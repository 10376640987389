/**
 * Patched to replace setTimeout with Ember.run.later
 */

/* ========================================================================
 * Bootstrap: transition.js v3.3.6
 * http://getbootstrap.com/javascript/#transitions
 * ========================================================================
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * ======================================================================== */

if (typeof FastBoot === 'undefined') {

  +function($) {
    'use strict';

    // CSS TRANSITION SUPPORT (Shoutout: http://www.modernizr.com/)
    // ============================================================

    function transitionEnd() {
      var el = document.createElement('bootstrap')

      var transEndEventNames = {
        WebkitTransition: 'webkitTransitionEnd',
        MozTransition: 'transitionend',
        OTransition: 'oTransitionEnd otransitionend',
        transition: 'transitionend'
      }

      for (var name in transEndEventNames) {
        if (el.style[name] !== undefined) {
          return { end: transEndEventNames[name] }
        }
      }

      return false // explicit for ie8 (  ._.)
    }

    // http://blog.alexmaccaw.com/css-transitions
    $.fn.emulateTransitionEnd = function(duration) {
      var called = false
      var $el = this
      $(this).one('bsTransitionEnd', function() {
        called = true
      })
      var callback = function() {
        if (!called) {
          $($el).trigger($.support.transition.end)
        }
      }
      Ember.run.later(this, callback, duration)
      return this
    }

    $(function() {
      $.support.transition = transitionEnd()

      if (!$.support.transition) {
        return
      }

      $.event.special.bsTransitionEnd = {
        bindType: $.support.transition.end,
        delegateType: $.support.transition.end,
        handle: function(e) {
          if ($(e.target).is(this)) {
            return e.handleObj.handler.apply(this, arguments)
          }
        }
      }
    })

  }(jQuery);

}
